import { kenkoDeepTeal, mossGreen100 } from "../../../shared/assets/colors";

export const contactItem = {
  color: kenkoDeepTeal,
  padding: "32px 20px",
  textAlign: "center",
};

export const contactForm = {
  display: "flex",
  border: `1px solid ${mossGreen100}`,
  borderRadius: "5px",
};

export const contactItemTitle = `
  font-weight: 700 !important;
  color: #124647;
  line-height: 30px;

  @media (max-width: 600px) {
    font-size: 20px !important;
  }
`;

export const contactItemSubtitle = `
  opacity: 0.6;
  max-width: 80%;

  @media (max-width: 600px) {
    margin:0 auto;
  }
`;
