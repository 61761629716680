import { IconProps } from "../types";

const Email = ({ width, height, color, background }: IconProps) => {
  return (
    <svg
      width={width || "40"}
      height={height || "40"}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill={background || "#D1DDB7"} />
      <path
        d="M20.7568 19.9583L12.027 14.5417C11.6847 17.6111 11 24.075 11 25.375C11 26.675 12.027 27 12.5405 27H27.4324C29.0757 27 29.8288 26.6389 30 26.4583V14L28.973 14.5417L20.7568 19.9583Z"
        fill="#F6F5EA"
      />
      <path
        d="M31 13.125C31 11.9562 30.055 11 28.9 11H12.1C10.945 11 10 11.9562 10 13.125V25.875C10 27.0438 10.945 28 12.1 28H28.9C30.055 28 31 27.0438 31 25.875V13.125ZM28.9 13.125L20.5 18.4375L12.1 13.125H28.9ZM28.9 25.875H12.1V15.7812L20.5 21.0938L28.9 15.7812V25.875Z"
        fill={color || "#003032"}
        fillOpacity="0.87"
      />
    </svg>
  );
};

export default Email;
