import { IconProps } from "../types";

const CheckList = ({ width, height, color, background }: IconProps) => {
  return (
    <svg
      width={width || "40"}
      height={height || "40"}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill={background || "#D1DDB7"} />
      <rect
        x="12.6406"
        y="11.085"
        width="14.56"
        height="9.89347"
        fill="#EDF0DF"
      />
      <path
        opacity="0.87"
        d="M26.8494 27.4513H26.8489L26.8494 27.4565C26.8466 28.1535 26.2815 28.7174 25.5852 28.7174H14.5298C13.8317 28.7174 13.2656 28.1507 13.2656 27.4513V12.6906C13.2656 11.9913 13.8317 11.4246 14.5298 11.4246H25.5852C26.2832 11.4246 26.8494 11.9913 26.8494 12.6906V27.4513ZM14.5298 10.2665C13.1926 10.2665 12.1088 11.352 12.1088 12.6906V27.4513C12.1088 28.79 13.1926 29.8755 14.5298 29.8755H25.5852C26.9224 29.8755 28.0062 28.79 28.0062 27.4513V12.6906C28.0062 11.352 26.9224 10.2665 25.5852 10.2665H14.5298Z"
        fill={color || "#003032"}
        stroke={color || "#003032"}
        strokeWidth="0.235513"
      />
      <path
        opacity="0.87"
        d="M15.9123 11.1886L15.7945 11.1886V11.3064V11.7674C15.7945 12.1272 15.9762 12.4581 16.2488 12.7461C16.522 13.0347 16.8956 13.2904 17.3039 13.5027C18.1183 13.9264 19.0985 14.1915 19.7581 14.1915H19.7833H20.8722H20.8808L20.8893 14.1903C21.5238 14.0972 22.3713 13.8346 23.0633 13.4316C23.7474 13.0333 24.3216 12.4719 24.3216 11.7674V11.3063V11.1885L24.2039 11.1885L23.4003 11.1885V11.1884H23.2826H16.8336H16.7158V11.1886L15.9123 11.1886ZM16.9513 11.4241L23.1648 11.424V11.7674C23.1648 11.9145 23.0853 12.0682 22.9302 12.2226C22.7761 12.376 22.5584 12.5184 22.3115 12.6403C21.8155 12.8852 21.2309 13.0335 20.8722 13.0335H19.7583H19.7581C19.3963 13.0335 18.6804 12.8836 18.0528 12.6364C17.7399 12.5131 17.4573 12.3689 17.2551 12.2128C17.0482 12.0532 16.9513 11.9016 16.9513 11.7674V11.4241ZM15.4997 16.7237C15.1802 16.7237 14.9213 16.983 14.9213 17.3027C14.9213 17.6223 15.1802 17.8817 15.4997 17.8817H24.6645C24.9841 17.8817 25.2429 17.6223 25.2429 17.3027C25.2429 16.983 24.9841 16.7237 24.6645 16.7237H15.4997ZM20.9807 23.4068C20.6612 23.4068 20.4023 23.6661 20.4023 23.9858C20.4023 24.3054 20.6612 24.5648 20.9807 24.5648H24.6659C24.9854 24.5648 25.2442 24.3054 25.2442 23.9858C25.2442 23.6661 24.9854 23.4068 24.6659 23.4068H20.9807Z"
        fill={color || "#003032"}
        stroke={color || "#003032"}
        strokeWidth="0.235513"
      />
      <path
        opacity="0.87"
        d="M19.1108 23.3902L17.2234 25.8239C17.1179 25.96 16.9573 26.042 16.7853 26.0476L16.7853 26.0476C16.6133 26.0532 16.4477 25.9818 16.3336 25.8528C16.3336 25.8528 16.3336 25.8528 16.3336 25.8528L14.9965 24.3418C14.7847 24.1025 14.8067 23.7366 15.0457 23.5244L15.0457 23.5244C15.2848 23.3123 15.6505 23.3343 15.8623 23.5738L16.7372 24.5624L18.197 22.68L18.197 22.68C18.393 22.4273 18.7564 22.3815 19.0088 22.5778C19.261 22.774 19.3066 23.1377 19.1108 23.3902ZM19.1108 23.3902L19.0177 23.3181L19.1108 23.3902Z"
        fill={color || "#003032"}
        stroke={color || "#003032"}
        strokeWidth="0.235513"
      />
    </svg>
  );
};

export default CheckList;
