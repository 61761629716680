import { IconProps } from "../types";

const Person = ({ width, height, color, background }: IconProps) => {
  return (
    <svg
      width={width || "40"}
      height={height || "40"}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill={background || "#D1DDB7"} />
      <path
        d="M11 28.6786V26.1071L13.8868 22.25L15.8113 21.2857L18.0566 20.3214L20.3019 20L24.1509 21.2857L28 26.1071V29L11 28.6786Z"
        fill="#F1F2E4"
      />
      <path
        opacity="0.87"
        d="M27.0258 23.1208C25.9007 21.7538 24.4481 20.6592 22.7642 20.0448C24.0035 18.9645 24.8439 17.3184 24.8439 15.5285C24.8439 12.4385 22.3663 9.72281 19.5 9.72281C16.6337 9.72281 14.1561 12.4385 14.1561 15.5285C14.1561 17.3189 14.9969 18.9652 16.2367 20.0455C12.4584 21.42 9.72281 24.9789 9.72281 28.8618C9.72281 29.1147 9.84845 29.4554 10.0491 29.729C10.2526 30.0065 10.5806 30.2772 11.0202 30.2772H27.8618C28.6435 30.2772 29.2772 29.6435 29.2772 28.8618C29.2772 27.2379 28.7097 25.801 28.1508 24.7777C27.8705 24.2646 27.5902 23.8516 27.3794 23.566C27.2739 23.4232 27.1856 23.312 27.1231 23.2358C27.0919 23.1978 27.0671 23.1685 27.0498 23.1483L27.0296 23.1251L27.0258 23.1208ZM15.9772 15.5285C15.9772 14.607 16.3545 13.612 16.9932 12.8486C17.6304 12.087 18.5079 11.578 19.5 11.578C20.4921 11.578 21.3696 12.087 22.0068 12.8486C22.6455 13.612 23.0228 14.607 23.0228 15.5285C23.0228 16.4499 22.6455 17.4449 22.0068 18.2083C21.3696 18.9699 20.4921 19.4789 19.5 19.4789C18.5079 19.4789 17.6304 18.9699 16.9932 18.2083C16.3545 17.4449 15.9772 16.4499 15.9772 15.5285ZM13.2676 28.2306C12.8096 28.2306 12.4018 28.0329 12.1464 27.7366C11.8944 27.4443 11.7899 27.0577 11.9237 26.6482C12.9641 23.4643 15.9802 21.3341 19.5 21.3341C23.0452 21.3341 26.0175 23.4629 27.0444 26.6463C27.1768 27.0566 27.0713 27.4438 26.8187 27.7364C26.5627 28.033 26.1548 28.2306 25.6968 28.2306H13.2676Z"
        fill={color || "#003032"}
        stroke={color || "#003032"}
        strokeWidth="0.554384"
      />
    </svg>
  );
};

export default Person;
